import { default as ai_45form_45builderqi9uAZxRA3Meta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/ai-form-builder.vue?macro=true";
import { default as editrbARJpAIMZMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexE8CjMplhPgMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexIKCYuIKmV8Meta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexeNXUvsGXsRMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareGNydEH7N0PMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsFF4GTBA9CCMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsdznd8qH07nMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showVJS1td9FUnMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guest5FpsM2h45DMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/create/guest.vue?macro=true";
import { default as indexYd9aATmxS4Meta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/create/index.vue?macro=true";
import { default as homeWiB2sCvZ3CMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/home.vue?macro=true";
import { default as index35517SFo5OMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/index.vue?macro=true";
import { default as loginDLLgHb5VWHMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/login.vue?macro=true";
import { default as callbackFpNQa6E7POMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/oauth/callback.vue?macro=true";
import { default as emailUdWD73YdlXMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/password/email.vue?macro=true";
import { default as _91token_93BYxqdXE73RMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/password/reset/[token].vue?macro=true";
import { default as pricing38wyStrdVIMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyWbJD0cYxR7Meta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/privacy-policy.vue?macro=true";
import { default as registerDVWrwQLwUTMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/register.vue?macro=true";
import { default as access_45tokensPPB2uLbvqqMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/access-tokens.vue?macro=true";
import { default as account1coG8U8mMCMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/account.vue?macro=true";
import { default as adminkpSgHcWoLZMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/admin.vue?macro=true";
import { default as billingdx55gpe9jwMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/billing.vue?macro=true";
import { default as connectionsAXVcBVl9kWMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/connections.vue?macro=true";
import { default as indexd4S2r0pF5OMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/index.vue?macro=true";
import { default as passwordhe5oQtGBVjMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/password.vue?macro=true";
import { default as profilePOQIKf2UdxMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/profile.vue?macro=true";
import { default as workspaceMLaVQJVkYZMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/workspace.vue?macro=true";
import { default as settingsS6tbZodnkVMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/settings.vue?macro=true";
import { default as errorQVIlqeEqFBMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/subscriptions/error.vue?macro=true";
import { default as successFzyNYKoXkRMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93DQVfzlNvxCMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/templates/[slug].vue?macro=true";
import { default as indexEmnrV3OJOJMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93QEVzqFRtGdMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatespPGqcb8PJrMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93nIozHhWVYLMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsS434feh1bgMeta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialsOVH4ae7zj1Meta } from "/codebuild/output/src3481790486/src/OpnForm/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderqi9uAZxRA3Meta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editrbARJpAIMZMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showVJS1td9FUnMeta?.name,
    path: "/forms/:slug()/show",
    meta: showVJS1td9FUnMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexIKCYuIKmV8Meta?.redirect,
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexeNXUvsGXsRMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: shareGNydEH7N0PMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: statsFF4GTBA9CCMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionsdznd8qH07nMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guest5FpsM2h45DMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexYd9aATmxS4Meta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homeWiB2sCvZ3CMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginDLLgHb5VWHMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emailUdWD73YdlXMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93BYxqdXE73RMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricing38wyStrdVIMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyWbJD0cYxR7Meta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerDVWrwQLwUTMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsS6tbZodnkVMeta?.name,
    path: "/settings",
    meta: settingsS6tbZodnkVMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: account1coG8U8mMCMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminkpSgHcWoLZMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingdx55gpe9jwMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionsAXVcBVl9kWMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexd4S2r0pF5OMeta?.redirect,
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordhe5oQtGBVjMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profilePOQIKf2UdxMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspaceMLaVQJVkYZMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errorQVIlqeEqFBMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successFzyNYKoXkRMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatespPGqcb8PJrMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsS434feh1bgMeta || {},
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/codebuild/output/src3481790486/src/OpnForm/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]